import React, { useContext, useState } from 'react';
import { navigate } from 'gatsby';

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  TextField,
  InputLabel,
  FormHelperText,
  CircularProgress,
} from '@mui/material';

import { adsProviders } from '../../constants';
import isBrowser from '../../utils/isBrowser';

import { UserContext } from '../../context/UserContext';
import faunadb from 'faunadb';

import * as styles from './Form.module.scss';

function Form() {
  const {
    name,
    adsProvider,
    email,
    websiteUrl,
    isConsent,
    handleChange,
    handleError,
  } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
    isBrowser &&
      window.gtag('event', 'click', {
        category: 'Button',
        action: 'Click',
        label: '2nd Page Button',
      });

    const errObj = {};

    if (!name.value.length) {
      errObj['name'] = 'Please provide a valid name';
    }

    if (!adsProvider.value.length) {
      errObj['adsProvider'] = 'Please provide a valid Ads Provider';
    }

    if (!email.value.length) {
      errObj['email'] = 'Please provide a valid email';
    }

    if (!websiteUrl.value.length) {
      errObj['websiteUrl'] = 'Please provide a valid website url';
    }

    if (Object.keys(errObj).length) {
      handleError(errObj);
    } else {
      setIsLoading(true);

      const data = {
        name: name.value,
        adsProvider: adsProvider.value,
        email: email.value,
        websiteUrl: websiteUrl.value,
        isConsent: Boolean(isConsent.value),
      };

      const client = new faunadb.Client({
        secret: 'fnAEenodVfAAxBQCKju1Rs4b76ZdX3mBRMhErbQq',
        domain: 'db.eu.fauna.com',
      });

      const q = faunadb.query;

      client
        .query(q.Create(q.Collection('user'), { data }))
        .then(() => {
          isBrowser && sessionStorage.removeItem('tracy');
          navigate('/contact');
        })
        .catch(() => alert('Something went wrong. Please try again!'))
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <div className={styles.container}>
      <h1>Join the waitlist</h1>
      <form>
        <div className={styles.inputWrapper}>
          <span>Hey, my name is</span>
          <TextField
            sx={{ minWidth: 100, width: 250, marginRight: 2, marginLeft: 2 }}
            variant='standard'
            type='text'
            placeholder='Name'
            name='name'
            onChange={handleChange}
            value={name.value}
            error={!!name.error.length}
            helperText={name.error}
          />

          <span>and I want Tracy for</span>
          <TextField
            sx={{ minWidth: 100, width: 250, marginRight: 2, marginLeft: 2 }}
            variant='standard'
            type='text'
            placeholder='Website URL'
            name='websiteUrl'
            onChange={handleChange}
            value={websiteUrl.value}
            error={!!websiteUrl.error.length}
            helperText={websiteUrl.error}
          />
          <span style={{ marginRight: 10 }}>.</span>

          <span>My Ads Provider is</span>
          <FormControl
            variant='standard'
            sx={{ minWidth: 50, width: 250, marginRight: 2, marginLeft: 2 }}
          >
            {!adsProvider.value.length && (
              <InputLabel id='select-ads-provider-label'>Ads Provider</InputLabel>
            )}

            <Select
              labelId='select-ads-provider-label'
              value={adsProvider.value}
              onChange={handleChange}
              label='Ads Provider'
              name='adsProvider'
              error={!!adsProvider.error.length}
            >
              {adsProviders.map(provider => (
                <MenuItem key={provider} value={provider}>
                  {provider}
                </MenuItem>
              ))}
            </Select>

            {!!adsProvider.error.length && (
              <FormHelperText error={true}>{adsProvider.error}</FormHelperText>
            )}
          </FormControl>

          <span>and My Email is</span>
          <TextField
            sx={{ minWidth: 100, width: 250, marginRight: 2, marginLeft: 2 }}
            variant='standard'
            type='text'
            placeholder='Business Email'
            name='email'
            onChange={handleChange}
            value={email.value}
            error={!!email.error.length}
            helperText={email.error}
          />
          <span>.</span>
        </div>

        <FormGroup>
          <FormControlLabel
            control={<Checkbox />}
            label='I here by give consent to be contacted by Ads Pusher.'
            className={styles.consent}
            name='isConsent'
            value={isConsent.value}
            onChange={(event, checked) => {
              event.target.value = Boolean(checked);
              handleChange(event);
            }}
          />
        </FormGroup>

        <Button
          className={styles.btn}
          disabled={isConsent.value === 'false' || isLoading}
          variant='contained'
          onClick={handleSubmit}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Join The Waitlist ➜'}
        </Button>
      </form>
    </div>
  );
}

export default Form;
