import * as React from 'react';
import Form from '../components/Form';
import Navbar from '../components/Navbar';

import { Helmet } from 'react-helmet';

import { UserContextProvider } from '../context/UserContext';

import './index.module.scss';

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Tracy - Your Ads Revenue Booster</title>
        <link rel='canonical' href='https://tracy.pages.dev/waitlist' />
      </Helmet>

      <UserContextProvider>
        <Navbar />
        <Form />
      </UserContextProvider>
    </>
  );
};

export default IndexPage;
